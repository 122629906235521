import {
    Loader,
    View,
} from "@aws-amplify/ui-react";
import React from "react";
import Gallery from "react-photo-gallery";
import './Home.scss';
import './Shared.scss'

let HomeData = {
    'bannerTitle': 'हार नहीं मानूंगा, रार नहीं थानुगा',
    'bannerTitleBy': '-- Atal Bihari Vajpayee',
    'vmTitle': 'Vission - Mission',
    'vmText': ["My mission is ", <b>to work with dedication and selflessness </b>, "towards achieving the goal of providing good governance to the people and ensuring equal development and welfare for all. -- ", <b>B.V.G</b>],
    'raTitle':'Recent activities',
    'raText': ["Bukka Venu Gopal garu met Andra Pradesh CM Nara Chandra Babu Naidu garu and Deputy CM Chinna Rajappa garu at Amaravathi,",<b> asking MLA ticket for Telugu Desam Party for Rajendra nagar constituency.</b>],
    'galleryTitle': 'Latest from gallery',
};

const Home = ({ mediaList, stillLoading }) => {
    return (
        <View className="App">
            <div className="bvg-home">
                <div className="bvg-banner-container">
                    <div className="bvg-banner">

                    </div>
                    <header className="bvg-home-header">
                        <h1>
                            <p className="head1">{HomeData.bannerTitle}</p>
                            <p className="byline">{HomeData.bannerTitleBy}</p>
                        </h1>
                    </header>
                </div>

                <div className="bvg-vision-mission">
                    <h2>{HomeData.vmTitle}</h2>
                    <p>{HomeData.vmText}</p>
                </div>

                <div className="bvg-home-gallery">
                    <h2>{HomeData.galleryTitle}</h2>
                    {
                        (!stillLoading && mediaList.length !== 0) ?
                        <Gallery photos={mediaList.slice(0,5)} direction={"row"} />
                        :  <Loader size="large" />
                    }
                </div>
            </div>
        </View>
    );
}

export default Home;
