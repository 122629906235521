import React, {useEffect, useState} from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter as Router, Routes, Route, Navigate}
    from 'react-router-dom';
import {
    View,
} from "@aws-amplify/ui-react";
import Navbar from './components/Navigation';
import Home from "./components/Home";
import AboutBVG from "./components/AboutBVG";
import GalleryPage from "./components/Gallery";
import Contact from "./components/Contact";
import UploadMedia from "./components/Upload";
import { API, Storage } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { listNotes } from "./graphql/queries";

const App = () => {
    const [mediaList, setMediaList] = useState([]);
    const [stillLoading, setStillLoading] = useState(true);

    const updateMediaList = (updatedMediaListFromAPI) => {
        setMediaList(updatedMediaListFromAPI);
    }

    async function fetchMediaList() {
        const apiData = await API.graphql({ query: listNotes, authMode: GRAPHQL_AUTH_MODE.API_KEY  });
        let mediaListFromAPI = apiData.data.listNotes.items;

        //Update the S3 image
        const _modifiedMediaList = [];
        mediaListFromAPI.forEach((item, index) => {
            const s3ImageUrl = Storage.get(item.name);
            s3ImageUrl.then((imageUrl) => {
                mediaListFromAPI[index].image = imageUrl;
                _modifiedMediaList.push({
                    src: imageUrl,
                    width: item.width,
                    height: item.height,
                    id: item.id,
                    key: item.id,
                    name: item.name
                })
                setMediaList(_modifiedMediaList);
            })
        })
    }


    useEffect(() => {
        fetchMediaList().then((data) => {
            setStillLoading(false);
        })
    }, []);

    return (
        <View className="App">
            <Router>
                <Navbar />
                <Routes>
                    <Route path='/' element={<Navigate to="/Home" />}  />
                    <Route path='/Home' element={<Home mediaList={mediaList} stillLoading={stillLoading} />} />
                    <Route path='/AboutBVG' element={<AboutBVG />} />
                    <Route path='/ContactUs' element={<Contact />} />
                    <Route path='/Gallery' element={<GalleryPage mediaList={mediaList} stillLoading={stillLoading}  />} />
                    <Route path='/Upload' element={<UploadMedia mediaList={mediaList} stillLoading={stillLoading} updateMediaList={updateMediaList} />} />
                </Routes>
            </Router>
        </View>
    );
};

export default App;