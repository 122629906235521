import React from "react";
import { Nav, NavLink, NavMenu }
    from "./NavBarElements";

const Navbar = () => {
    return (
        <>
            <Nav>
                <NavMenu>
                    <NavLink to="/Home" activeStyle>
                        Home
                    </NavLink>
                    <NavLink to="/AboutBVG" activeStyle>
                        About
                    </NavLink>
                    <NavLink to="/Gallery" activeStyle>
                        Gallery
                    </NavLink>
                    <NavLink to="/ContactUs" activeStyle>
                        Contact Us
                    </NavLink>
                    <NavLink to="/Upload" activeStyle>
                        Upload
                    </NavLink>

                </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;

