import React from "react";
import "./Upload.css";
import "@aws-amplify/ui-react/styles.css";
import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api";
import { API, Storage } from "aws-amplify";
import { listNotes } from "../graphql/queries";
import {
    createNote as createNoteMutation,
    deleteNote as deleteNoteMutation,
} from "../graphql/mutations";
import {getImageSize} from "react-image-size";
import {
    Button,
    Heading,
    Image,
    View,
    Card,
    withAuthenticator, FileUploader, Collection
} from "@aws-amplify/ui-react";

const Upload = ({ mediaList, stillLoading, updateMediaList, signOut }) => {

    const getDimensions = async (name, imageUrl) => {
        try {
            const {width, height} = await getImageSize(imageUrl);
            return {
                image : 'imageUrl',
                name: name,
                width : width || 1,
                height : height || 1
            }
        } catch (e) {
            return {
                image : 'imageUrl',
                name: name,
                width :  1,
                height : 1
            }
        }
    };

    async function createNote(createNodeParams) {
        const data = { ...createNodeParams };
        await API.graphql({
            query: createNoteMutation,
            variables: { input: data, authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS },
        });
    }


    async function fetchMediaList() {
        const apiData = await API.graphql({ query: listNotes, authMode: GRAPHQL_AUTH_MODE.API_KEY });
        const mediaListFromAPI = apiData.data.listNotes.items;

        //update with latest S3Image url.
        const _modifiedMediaList = [];
        mediaListFromAPI.forEach((item, index) => {
            const s3ImageUrl = Storage.get(item.name);
            s3ImageUrl.then((imageUrl) => {
                mediaListFromAPI[index].image = imageUrl;
                _modifiedMediaList.push({
                    src: imageUrl,
                    width: item.width,
                    height: item.height,
                    id: item.id,
                    key: item.id,
                    name: item.name,
                })
                updateMediaList(_modifiedMediaList)
            })
        })
    }

    const onSuccess = async (event) => {
        const uploadedImage = Storage.get(event.key, {level: 'public', acl:'public-read'});
        uploadedImage.then((imageUrl) => {
            const uploadedImageObjectWithDimensions = getDimensions(event.key, imageUrl);
            uploadedImageObjectWithDimensions.then((createNoteData) => {
                createNote(createNoteData)
                fetchMediaList();
            })
        })
    }


    async function deleteNote({ id, name }) {
        const newMediaList = mediaList.filter((media) => media.id !== id);
        updateMediaList(newMediaList);

        await Storage.remove(name);
        await API.graphql({
            query: deleteNoteMutation,
            variables: { input: { id } },
        });
    }

    return (
        <>

            <View className="App">
                <Button onClick={signOut}>Sign Out</Button>
                <Heading level={3}>Upload Images to www.bukkavenugopal.com website</Heading>
                <FileUploader
                    isPreviewerVisible={true}
                    acceptedFileTypes={["image/*"]}
                    accessLevel="public"
                    variation="drop"
                    onSuccess={onSuccess}
                    maxFileCount={105}
                    isResumable
                    showImages={true}
                />

                <Heading level={3}>Stored Media</Heading>

                <Collection items={mediaList}
                            type="grid"
                            direction="row"
                            gap="medium"
                            wrap="wrap"
                            isPaginated
                            itemsPerPage={18}
                            templateColumns="1fr 1fr 1fr"
                            padding="2rem"
                >
                    {(item, index) => (
                        <>
                            <Card
                                key={index}
                                borderRadius="medium"
                                variation="outlined"
                                templateColumns="1fr 1fr 1fr"
                                templateRows="12rem 12rem 12rem"
                            >
                                <Image
                                    src={item.src}
                                    alt={item.name}
                                />
                                <View padding="xs">
                                    <Heading padding="medium">Name: {item.name}</Heading>
                                    <Button variation="primary"  isFullWidth onClick={() => deleteNote(item)} >
                                        Delete
                                    </Button>
                                </View>
                            </Card>
                        </>
                    ) }
                </Collection>
            </View>
        </>
    );
};

export default withAuthenticator(Upload, {hideSignUp: true, });