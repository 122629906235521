import React, {useState} from 'react';
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './Gallery.scss';

import {
    Loader
} from "@aws-amplify/ui-react";

function GalleryPage({ mediaList, stillLoading }) {
    const [index, setIndex] = useState(-1);

    const handleClick = (event, item) => {
        console.log('item');
        console.log(item);
        setIndex(item.index);
    }
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const currentImage = mediaList[index];
    const nextIndex = (index + 1) % mediaList.length;
    const nextImage = mediaList[nextIndex] || currentImage;
    const prevIndex = (index + mediaList.length - 1) % mediaList.length;
    const prevImage = mediaList[prevIndex] || currentImage;

    return (
        <div>
            { (!stillLoading && mediaList.length !== 0)
                ?
                (
                    <div>
                        <Gallery photos={mediaList} direction={"column"} onClick={handleClick} />
                        {!!currentImage && (
                            /* @ts-ignore */
                            <Lightbox
                                mainSrc={currentImage.src}
                                // imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.src}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.src}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                            />
                        )}
                    </div>

                ):  <Loader
                    size="small"
                    variation="linear"
                />}
        </div>


    );
}
export default GalleryPage;